import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InDateFilter } from 'src/app/interfaces/filter/date-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class  FilterDataService {

  private dateFilterSubject: BehaviorSubject<InDateFilter> = new BehaviorSubject<InDateFilter>({
    start: null,
    end: null
  } as InDateFilter);
  public dateFilter: Observable<InDateFilter> = this.dateFilterSubject.asObservable();
  private selectedFiltersSource = new BehaviorSubject<any>(null);
  selectedFilters$ = this.selectedFiltersSource.asObservable();

  constructor(
    private datepipe: DatePipe
  ) { }
  setSelectedFilters(filters: any) {
    this.selectedFiltersSource.next(filters);
  }
  
  public get dateFilterValue(): InDateFilter {
    return this.dateFilterSubject.value;
  }
  
  public setDateFilter(data?: InDateFilter): void {
    if (!data) {
      const currentDate = new Date();
      const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      //const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),  currentDate.getDate());

      data = {
        start: this.datepipe.transform(minDate, 'yyyy-MM-dd'),
        start_complete: minDate.toISOString(),
        end: this.datepipe.transform(maxDate, 'yyyy-MM-dd'),
        end_complete: maxDate.toISOString()
      } as InDateFilter;
    }
    this.dateFilterSubject.next(data);
  }
}