<section class="content">
    <div class="container-fluid">
      <div class="block-header" *ngFor="let breadscrum of breadscrums">
        <!-- breadcrumb -->
        <app-breadcrumb [title]="breadscrum.title" [items]="breadscrum.items" [active_item]="breadscrum.active">
        </app-breadcrumb>
      </div>
      <div class="">
        <!-- section content -->
        <router-outlet></router-outlet>
      </div>
    </div>
</section>
  