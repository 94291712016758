<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-lg-4">
      <div class="auth-content p-4 d-flex align-items-center min-vh-100">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div>
                <div class="text-center">
                  <div>
                    <a routerLink="" class="logo-full"><img src="assets/images/logo-full.png" alt="logo-full"></a>
                  </div>

                  <h4 class="font-20 mt-4">Bienvenido</h4>
                  <p class="text-muted">Ingresa tus datos para continuar</p>
                </div>
                <div class="w-100">
                  <!-- Resto del HTML del formulario -->
                  <form [formGroup]="registerForm" (ngSubmit)="register()">
                    <!-- Aquí van los campos del formulario -->
                    <div *ngIf="error" class="alert alert-danger mb-3 mt-0">{{error}}</div>

                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">account_circle</mat-icon>
                      <input type="text" formControlName="username" class="form-control auth-control"
                        placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Nombre requerido</div>
                      </div>
                    </div>

                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">account_circle</mat-icon>
                      <input type="text" formControlName="last_name" class="form-control auth-control"
                        placeholder="Apellido" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                      <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                        <div *ngIf="f.last_name.errors.required">Apellido requerido</div>
                      </div>
                    </div>

                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">email</mat-icon>
                      <input type="email" formControlName="email" class="form-control auth-control"
                        placeholder="Correo electrónico" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Correo requerido</div>
                        <div *ngIf="f.email.errors.email">Ingresa un correo válido</div>
                      </div>
                    </div>

                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                      <input type="password" formControlName="password" class="form-control auth-control"
                        placeholder="Contraseña" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Contraseña requerida</div>
                        <div *ngIf="f.password.errors.minlength">Mínimo 6 caracteres</div>
                      </div>
                    </div>

                    <div class="form-group auth-form-group mb-4">
                      <mat-icon matSuffix class="auth-page-icon">vpn_key</mat-icon>
                      <input type="password" formControlName="confirmPassword" class="form-control auth-control"
                        placeholder="Confirmar Contraseña" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Confirmación requerida</div>
                      </div>
                    </div>

                    <!-- Botón de registro -->
                    <div class="mt-5 text-center">
                      <mat-spinner-button style="width: 100%;"
                        [options]="registerProgressBtn"
                        [active]="registerProgressBtn.active"
                        (btnClick)="register()">
                      </mat-spinner-button>
                    </div>

                  </form>
                </div>
              </div>


              <div class="mt-5 text-center">
                <p>¿Quieres iniciar sesion? <a routerLink="/authentication/signin"
                    class="font-weight-medium text-primary">
                    Iniciar Sesion </a> 
                </p>
              </div>

            </div>
          </div>
        </div>
        <!-- Resto de tu HTML, como la sección de imagen de fondo -->
      </div>
    </div>
  </div>
</div>
